import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStoreService } from '../services/local-store.service';
import { StoreName } from '../enums/store-name.enum';
import { inject, Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root',
// })
// export class OnlineFormGuard {
//   public constructor(private store: LocalStoreService, private router: Router) {
//     console.log('entra OnlineFormGuard');
//   }

//   canActivate(
//     next: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot
//   ): Observable<boolean> | Promise<boolean> | boolean {
//     console.log('entra 1');

//     const formId = this.store.get<string>(StoreName.FORM_ID);
//     const customerId = this.store.get<string>(StoreName.CUSTOMER_ID);
//     if (formId || customerId) {
//       console.log('entra');

//       return true;
//     } else {
//       this.router.navigate(['/coface/home']);
//       return false;
//     }
//   }
// }

export const onlineFormGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const store = inject(LocalStoreService);
  const router = inject(Router);
  const formId = store.get<string>(StoreName.FORM_ID);
  const customerId = store.get<string>(StoreName.CUSTOMER_ID);
  if (formId || customerId) {
    return true;
  } else {
    router.navigate(['/coface/home']);
    return false;
  }
};

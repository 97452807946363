import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalService } from '../../../commons/services/dialog.service';
import { InvalidFileComponent } from '../invalid-file/invalid-file.component';
import { FileCoface } from '../../../modules/online-forms/common/model';

@Component({
  selector: 'coface-input-file-online-form',
  templateUrl: './input-file-online-form.component.html',
  styleUrl: './input-file-online-form.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFileOnlineFormComponent),
      multi: true,
    },
  ],
})
export class InputFileOnlineFormComponent implements ControlValueAccessor {
  @Input() public placeholder: string = 'Cargar Documento';
  @Input() public closeBigger: boolean = false;
  @Input() public shadow: boolean = true;
  @Input() public accept: string = '.pdf, .docx, .xlsx';
  @Output() public uploadedFile = new EventEmitter<File>();
  @Output() public deleteFile = new EventEmitter<void>();

  @ViewChild('uploader', { static: false }) uploader: ElementRef;

  fileLoaded: string;
  showFileLoaded: boolean = false;

  public constructor(private modalService: ModalService) {}

  private onChange: (value: any) => void;
  private onTouched: () => void;

  fileEvent(e: Event) {
    let file = (<HTMLInputElement>e.target).files;
    // VALIDATE SIZE FILE MAX 50MB AND FORBIDDEN EXTENSIONS .ZIP .RAR

    if (file != null) {
      const isInvalid = this.invalidFile(file[0]);
      if (isInvalid.invalidSize) {
        this.modalService.open(
          {
            invalidText: isInvalid.invalidSize
              ? `El archivo ${file[0].name} excede el límite de tamaño permitido de 50 MB. Por favor, reduzca el tamaño del archivo e intente subirlo nuevamente.`
              : isInvalid.invalidExtension
              ? 'Formato de archivo no admitido. Los archivos comprimidos(ZIP/RAR) no están permitidos. Por favor, seleccione un formato de archivo compatible e intente nuevamente la carga.'
              : '',
          },
          InvalidFileComponent,
          {
            size: 'SMALL',
            closable: '',
            disableClose: false,
          }
        );
        return;
      }

      const oneFile = file[0] as FileCoface;
      this.showFileLoaded = true;
      this.fileLoaded = oneFile?.name;
      this.onChange(oneFile);
      this.onTouched();
      this.uploadedFile.emit(oneFile);
    }
  }

  private invalidFile(file: File): {
    invalidSize: boolean;
    invalidExtension: boolean;
  } {
    if (!this.validateFileSize50MB(file)) {
      return {
        invalidSize: true,
        invalidExtension: false,
      };
    }
    // if (!this.validateFileExtension(file)) {
    //   return {
    //     invalidSize: false,
    //     invalidExtension: true,
    //   };
    // }

    return {
      invalidSize: false,
      invalidExtension: false,
    };
  }

  private validateFileSize50MB(file: File) {
    const fileSizeInMB = file.size / 1024 / 1024;
    if (fileSizeInMB > 50) {
      return false;
    }
    return true;
  }

  private validateFileExtension(file: File) {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (fileExtension === 'zip' || fileExtension === 'rar') {
      return false;
    }
    return true;
  }

  changeFileEvent() {
    if (this.showFileLoaded) {
      this.showFileLoaded = !this.showFileLoaded;
      // this.uploader.value = '';
      this.uploader.nativeElement.value = '';
      this.onChange(null);
      this.onTouched();
      this.deleteFile.emit();
    }
  }

  // tslint:disable-next-line: no-any
  public registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  public writeValue(value: File | null) {
    if (value) {
      this.showFileLoaded = true;
      this.fileLoaded = value.name;
    } else {
      this.showFileLoaded = false;
      this.fileLoaded = this.placeholder;
    }
  }
}

import { Injectable } from '@angular/core';
import { JSEncrypt } from 'jsencrypt';
import { environment } from 'projects/ui-coface/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RsaEncryptionService {
  public publicKey = environment.keyEncrypted;
  constructor() {}

  public encrypt<T>(data: T) {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(environment.keyEncrypted.trim());
    return encrypt.encrypt(data);
  }

  public decrypt<T>(data: T) {
    const decrypt = new JSEncrypt();
    decrypt.setPrivateKey(''.trim());
    return decrypt.decrypt(data);
  }
}

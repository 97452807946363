import { Injectable } from '@angular/core';
import { FormsHttp } from '../http/forms.http';

@Injectable({
  providedIn: 'root',
})
export class FormsOperation {
  constructor(private http: FormsHttp) {}

  public finishNotify$(formId: string) {
    return this.http.finishNotify$(formId);
  }

  public correctionsNotify$(formId: string) {
    return this.http.correctionsNotify$(formId);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseCoface } from 'projects/ui-coface/src/app/commons/interface/response-coface.interface';
import { environment } from 'projects/ui-coface/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FormsHttp {
  constructor(private http: HttpClient) {}
  public finishNotify$(formsId: string) {
    return this.http.post<IResponseCoface<any>>(
      `${environment.urlBaseEndpointV2}/forms/finish/${formsId}`,
      {}
    );
  }
  public correctionsNotify$(formsId: string) {
    return this.http.post<IResponseCoface<any>>(
      `${environment.urlBaseEndpointV2}/forms/corrections/${formsId}`,
      {}
    );
  }
}

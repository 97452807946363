import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  catchError,
  combineLatest,
  delay,
  forkJoin,
  map,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { BulkLoadProvider } from '../../modules/provider/commons/model/bulk-load.paginator';
import { UploadHttp } from '../http/upload.http';
import {
  IUploadFile,
  IUploadFileRecalification,
} from '../interface/upload.interface';
import { FilterModel } from '../model/filter.model';
import { Paginator } from '../model/paginator.model';
import { GenericRequestConfig } from '../model/request.config';
import { ModalService } from '../services/dialog.service';
import { SessionService } from '../services/session.service';
import { environment } from 'projects/ui-coface/src/environments/environment';
import { LocalStoreService } from '../services/local-store.service';
import { StoreName } from '../enums/store-name.enum';

@Injectable({
  providedIn: 'root',
})
export class UploadOperation {
  public constructor(
    private uploadhHttp: UploadHttp,
    private dialog: ModalService,
    private session: SessionService,
    private store: LocalStoreService
  ) {}

  public onUpload$(fileCoverImage: File[]): Observable<IUploadFile[]> {
    let listObservable: Observable<IUploadFile>[] = [];

    for (const file of fileCoverImage) {
      const formData = new FormData();
      formData.append('document', file);
      listObservable.push(this.uploadhHttp.upload$(formData));
    }
    return forkJoin<IUploadFile[]>(listObservable).pipe();
  }

  uploadAttachmentToPlatform$(file: File, folder?: string) {
    const formData = new FormData();
    formData.append('document', file);
    return this.uploadhHttp.uploadAttachmentToPlatform(formData, folder).pipe();
  }

  //recalificacion documents
  public uploadAttachmentsRecalification$(
    fileCoverImage: File[],
    folderData,
    idHomologation
  ) {
    const formData = new FormData();
    for (const file of fileCoverImage) {
      formData.append('documents', file);
    }
    return this.uploadhHttp
      .uploadAttachmentsRecalification(formData, folderData, idHomologation)
      .pipe();
  }

  //homologacion documents
  public uploadAttachmentsHomologation$(
    fileCoverImage: File[],
    folderData,
    idHomologation
  ) {
    const formData = new FormData();
    for (const file of fileCoverImage) {
      formData.append('documents', file);
    }
    return this.uploadhHttp
      .uploadAttachmentsHomologation(formData, folderData, idHomologation)
      .pipe();
  }

  //get File to platform
  // public getAttachmentsByFolder(folder){
  //   return this.uploadhHttp.getAttachmentsByFolder$(folder).pipe(delay(5000));
  // }

  //bulk upload
  public onMassiveUpload$(fileCover: File) {
    const formData = new FormData();
    formData.append('document', fileCover);
    return this.uploadhHttp.uploadMassive$(formData).pipe(delay(2000));
  }

  //bulk upload client
  public onMassiveUploadClient$(fileCover: File) {
    const formData = new FormData();
    formData.append('document', fileCover);
    return this.uploadhHttp.uploadMassiveClient$(formData).pipe(delay(2000));
  }

  // result bulk upload
  public onBulkUploadResult$(idUpload) {
    return this.uploadhHttp.uploadMassiveResult$(idUpload).pipe();
  }

  // record files upload
  public onToShowSummaryMassive(filters: FilterModel) {
    const body = this.builderRequest(filters);
    return this.uploadhHttp.toShowSummaryMassive$(body).pipe(
      map((res) => {
        const content = res.data['content'];
        let paginator = Paginator.backBuild<BulkLoadProvider>(res.data);
        if (content?.length) {
          const row = content.map((val) => BulkLoadProvider.backBuild(val));
          paginator.Content = row;
        }
        return paginator;
      })
    );
  }

  // download observed
  public onDownloadMassiveUpload$(idBulkUpload) {
    return this.uploadhHttp.downloadFileObserved$(idBulkUpload).pipe();
  }

  private builderRequest(filter: FilterModel) {
    const rq = new GenericRequestConfig();
    rq.params = new HttpParams();

    rq.params = rq.params.set('page', filter.Page - 1).set('size', filter.Size);

    return rq;
  }

  // new
  public uploadAttachment$(file: File, folderData: string, formId?: string) {
    const formIdStore = this.store.get<string>(StoreName.FORM_ID) ?? '81';
    const formData = new FormData();
    formData.append('document', file);
    formData.append('folder', folderData);
    return this.uploadhHttp.uploadAttachment$(formData, formIdStore).pipe();
  }

  downloadFile(id: any) {
    window.open(`${environment.urlBaseEndpoint}/attachments/download/${id}`);
  }

  public downloadMultipleFiles(idFiles: Array<string>) {
    return this.uploadhHttp.downloadMultipleFiles$(idFiles);
  }

  public data = [
    `forms/\g\/attachments/download/multiple`,
    `forms/2/attachments/download/multiple`,
    `forms/3/attachments/download/multiple`,
    `forms/4/attachments/download/multiple`,
    `forms/5/attachments/download/multiple`,
  ];
}

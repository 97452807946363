import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'coface-circle-progressbar',
  templateUrl: './circle-progressbar.component.html',
  styleUrls: ['./circle-progressbar.component.scss'],
})
export class CircleProgressbarComponent implements OnInit, OnChanges {
  // public
  @Input() currentPercent: number = 0;

  circumference = 2 * Math.PI * 40; // Radio del círculo
  offset = 0;
  constructor() {}

  ngOnInit(): void {
    this.updateProgress();
  }

  ngOnChanges() {
    this.updateProgress();
  }

  updateProgress() {
    const percent = Math.max(0, Math.min(this.currentPercent, 100)); // Asegura que esté entre 0 y 100
    this.offset = this.circumference - (percent / 100) * this.circumference;
  }
}

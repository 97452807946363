import { Injectable } from '@angular/core';
import { delay, map } from 'rxjs';
import { SessionService } from '../../../commons/services/session.service';
import { AuthHttp } from '../http/auth.http';
import { IAuthRequest, IUser } from '../interface/auth.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthOperation {
  public dataUser: IUser;
  public constructor(
    private authHttp: AuthHttp,
    private sessionService: SessionService
  ) {}

  public onLogin$(body: IAuthRequest) {
    const parseBody = {
      email: body.email.trim(),
      password: body.password.trim(),
    };
    return this.authHttp.auth$(parseBody).pipe(
      delay(1000),
      map((res) => {
        this.dataUser = res.data.user;
        this.sessionService.saveTokenToStorage(res.data.token, res.data.user);

        return 'SUCCESS';
      })
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormStatusType } from 'projects/ui-coface/src/app/commons/enums/form-status.enum';
import { StoreName } from 'projects/ui-coface/src/app/commons/enums/store-name.enum';
import { LocalStoreService } from 'projects/ui-coface/src/app/commons/services';
import { HomologacionOperation } from 'projects/ui-coface/src/app/modules/homologacion/commons/homologacion-operation';
import { FormsOperation } from 'projects/ui-coface/src/app/modules/online-forms/common/operation/forms.operation';
import { switchMap } from 'rxjs';

@Component({
  selector: 'coface-requalification-request',
  templateUrl: './requalification-request.component.html',
  styleUrls: ['./requalification-request.component.scss'],
})
export class RequalificationRequestComponent implements OnInit {
  message: string;
  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private router: Router,
    private homologationOperation: HomologacionOperation,
    private formOperation: FormsOperation,
    private store: LocalStoreService
  ) {}

  ngOnInit(): void {
  }

  requestRequalification() {
    const { homologationId, FormId } = this.config.data;

    if (!FormId) {
      this.homologationOperation
        .unlockFileHomologation(homologationId)
        .pipe()
        .subscribe((_) => {
          this.router
            .navigate(['../coface/homologacion/carga-documentos'], {
              queryParams: {
                homologationId: homologationId,
              },
            })
            .then((_) => {
              this.ref.close();
            });
        });
    } else {
      this.formOperation.correctionsNotify$(FormId.toString()).subscribe({
        next: () => {
          this.setterValuesToForms(this.config.data);
          this.ref.close();
          this.router.navigate([
            'coface/formularios-enlinea/informacion-general',
          ]);
        },
      });
    }
  }

  private setterValuesToForms(item) {
    this.store.set(StoreName.FORM_ID, item.FormId);
    this.store.set(StoreName.FORM_STATUS, FormStatusType.CORRECTIONS);
    this.store.set(StoreName.HOMLOGATION_ID, item.HomologationId);
    this.store.set(StoreName.TERMS_AND_CONDITIONS, item.IsProcessingData);
    this.store.set(StoreName.CUSTOMER_ID, item.OfficeId);
    this.store.set(StoreName.PERSON_TYPE, item.PersonType);
    this.store.set(StoreName.HOMOLOGATION_CONDITIONS, item.ConditionId)
  }
}

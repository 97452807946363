<section class="text-center px-4 leading-relaxed py-5">
  <p class="text-2xl text-coface-primary font-bold">Su sesión ha expirado</p>
  <p class="text-2xl text-coface-primary font-bold">
    debido a inactividad prolongada.
  </p>

  <p class="text-lg text-coface-primary font-normal mt-4">
    Por favor, inicie sesión nuevamente
  </p>

  <p class="text-lg text-coface-primary font-normal">
    para continuar con el proceso.
  </p>

  <button
    class="p-4 w-full text-xl font-bold text-white bg-coface-primary mt-4 block mx-auto rounded-lg"
    (click)="onAccept()"
  >
    Aceptar
  </button>
</section>

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlBaseEndpoint: 'https://dev.supplierportal.warender.me/api/v1',
  // urlBaseEndpoint: 'https://3059-45-5-69-87.ngrok-free.app',
  urlBaseEndpointV2: 'https://dev.supplierportal.warender.me/api/v1',
  // urlBaseEndpointV2: 'https://3059-45-5-69-87.ngrok-free.app',

  keyEncrypted: `
  -----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAq2JU8rWjDOCWlQD/gTRA
GI8CJ2Fm0IoJKq5zvPWTapl+Z5wcYEk3yf0Jmz1HGuHU8Fl0iWO2Ve1Gslo9BW+s
LuwB2L3SeQMTY8dFrpQI9czS12xpUHpTddAvHsj+UlXpgURFpWPaOQZwUbtYRyE5
Vd5gQKt3DZOaLiKvAuyR2tUvCrf3t+f/CJr0dkPc01ljbuZZhgqbbs3Q4oc1r14x
iki4jgQDFeYNvwRQgJra7K+fOdzfSbvxWEpS+C9sRGgwjcIpkX/noph/2gZTdUYn
IZoO8nQZzdvmJdw7WOZBXBwCa8O4hL16M2Pg3KxDqeNjBdlnqPmxRb2PCbeLOfnM
LQIDAQAB
-----END PUBLIC KEY-----
  `,
};
// http://stg.supplierportal.warender.me/api/v1/bulk/save-bulk-upload-client
/*http://dev.supplierportal.warender.me/api/v1/platform/users/roles

 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { Injectable } from '@angular/core';
import { AssignAnalystHttp } from '../http/assign-analyst.http';
import { map } from 'rxjs';
import {Parameter} from "../model/parameter.model";

@Injectable({
  providedIn: 'root',
})
export class AssignAnalystOperation {
  constructor(private analystHttp: AssignAnalystHttp) {}

  public getAnalyst$() {
    return this.analystHttp.getAnalyst$().pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  public assignAnalyst$(form: { operationId: number; analystId: number }) {
    return this.analystHttp
      .assignAnalyst$(form.operationId, form.analystId)
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  public getMappedAnalyst() {
    return this.getAnalyst$().pipe(
      map((analysts) => {
        return analysts.map((analyst) => {
          return new Parameter(analyst.id.toString(), `${analyst.firstName} ${analyst.lastName}`);
        }).concat(new Parameter('0', 'Sin Analista'));
      })
    );
  }
}

<!-- <div
  id="circle-progressbar"
  role="progressbar"
  aria-valuemin="0"
  aria-valuemax="100"
  style="--value: {{ currentPercent }}"
></div> -->
<div class="relative w-[100px] h-[100px]">
  <!-- Fondo del círculo -->
  <svg class="w-full h-full transform -rotate-90" viewBox="0 0 100 100">
    <circle
      class="text-blue-100 stroke-current"
      stroke-width="10"
      cx="50"
      cy="50"
      r="40"
      fill="transparent"
    />
    <circle
      class="text-coface-primary stroke-current"
      stroke-width="10"
      stroke-linecap="round"
      cx="50"
      cy="50"
      r="40"
      fill="transparent"
      [attr.stroke-dasharray]="circumference"
      [attr.stroke-dashoffset]="offset"
    />
  </svg>

  <!-- Número de porcentaje en el centro -->
  <div
    class="absolute top-0 left-0 w-full h-full flex items-center justify-center text-lg font-light"
  >
    {{ currentPercent }}%
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseCoface } from 'projects/ui-coface/src/app/commons/interface/response-coface.interface';
import { GenericRequestConfig } from 'projects/ui-coface/src/app/commons/model/request.config';
import { IResponseInformation } from '../interface/informacion-basica.interface';

@Injectable({
  providedIn: 'root',
})
export class InformacionBasicaEndpoint {
  constructor(private http: HttpClient) {}

  customerSupplierDetails$(req: GenericRequestConfig) {
    return this.http.post<IResponseCoface<IResponseInformation>>(
      req.url,
      req.body
    );
  }
}
